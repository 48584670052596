/**
 * Config.js
 * Where we we set all the configurations and constants to be used in the application
 */

import 'interceptors'

// ! Do not remove the env comments they are used for building the correct environemnt
// env
export * from 'environments/evora/production';
// env

// Core Constants
export const VERSION =
  process.env.VERSION && process.env.VERSION.replace(/"/g, '')
export const BUILD_DATE = process.env.BUILD_DATE
export const DEFAULT_DATE_FORMAT = `DD/MM/YYYY`
export const API_DATE_FORMAT = `YYYY-MM-DD`

// Field Constants

// Other Contants
